import React from 'react';
import { Modal, Table, Button } from 'antd';

const ExistingDrillModal = ({ isVisible, onClose, availableDrills, handleAddExistingDrills }) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {availableDrills.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h3>No more drills available to add to this session.</h3>
        </div>
      ) : (
        <Table
          dataSource={availableDrills}
          columns={[
            { title: 'Name', dataIndex: 'drill_name', key: 'drill_name' },
            { title: 'Duration (min)', dataIndex: 'duration', key: 'duration' },
            {
              title: 'Add to Plan',
              render: (_, record) => (
                <Button onClick={() => handleAddExistingDrills([record])}>Add</Button>
              ),
            },
          ]}
          rowKey="drill_id"
        />
      )}
    </Modal>
  );
};

export default ExistingDrillModal;