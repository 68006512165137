

import React, { useState } from 'react';
import { Tooltip, Form, Input, Button } from 'antd';
import { InfoCircleOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';

const AuthForm = ({ onSubmit, buttonText, isSignup = false }) => {
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);

  const passwordRules = [
    { required: true, message: 'Please input your password!' },
    ...(isSignup
      ? [
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
              return passwordRegex.test(value)
                ? Promise.resolve()
                : Promise.reject('Password does not meet requirements.');
            },
          },
        ]
      : []),
  ];

  const passwordInfo = (
    <ul style={{ paddingLeft: '20px', margin: 0,listStyle: 'none' }}>
      <li>At least 8 characters long</li>
      <li>At least one uppercase letter</li>
      <li>At least one lowercase letter</li>
      <li>At least one number</li>
      <li>At least one special character</li>
    </ul>
  );

  return (
    <Form onFinish={onSubmit} style={{ maxWidth: 300, margin: 'auto' }}>
      {isSignup &&
        ['firstName', 'lastName'].map((name) => (
          <Form.Item
            key={name}
            name={name}
            rules={[
              {
                required: true,
                message: `Please input your ${name === 'firstName' ? 'first' : 'last'} name!`,
              },
            ]}
          >
            <Input placeholder={`${name === 'firstName' ? 'First' : 'Last'} Name`} />
          </Form.Item>
        ))}
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={passwordRules}
        validateTrigger="onBlur" // Optional: Validate on blur instead of on change
        // Remove the extra prop if you don't want to show password info
        extra={
          isSignup && (
            <>
              <Button
                type="link"
                icon={<InfoCircleOutlined />}
                onClick={() => setShowPasswordInfo(!showPasswordInfo)}
                style={{ padding: 0 }}
              >
                Password Requirements
              </Button>
              {showPasswordInfo && passwordInfo}
            </>
          )
        }
      >
        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {buttonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AuthForm;

