// src/pages/Sessions.jsx
import React, { useEffect, useState } from "react";
import { Table, Button, message, Modal, Form, Input, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { MoreOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getSessions, getSessionDrills, getSessionDuration, updateSession, createSession, deleteSession, getSessionFiles } from './api/sessions';

const Sessions = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [editingSession, setEditingSession] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    const fetchSessions = async () => {
      console.log("Fetching sessions...");
      try {
        const response = await getSessions();
        const sessionsData = response.data;
        console.log("Fetched sessions data:", sessionsData);

        if (sessionsData.length === 0) {
          setSessions([]);
          return;
        }
        const sessionsWithDetails = await Promise.all(
          sessionsData.map(async (session) => {
            try {
              const sessionId = session.session_id;

              // Fetch drills and duration
              const drillsResponse = await getSessionDrills(sessionId);
              const drills = drillsResponse.data || [];
              const drillCount = drills.length;

              const totalDurationResponse = await getSessionDuration(sessionId);
              const totalDuration = totalDurationResponse.data.totalDuration || 0;

              // Fetch resource count
              const filesResponse = await getSessionFiles(sessionId);
              const resourceCount = filesResponse.data.length;

              return {
                ...session,
                drill_count: drillCount,
                total_duration: totalDuration,
                resources_count: resourceCount, // Add resource count to session data
              };
            } catch (error) {
              console.error("Error fetching session details:", error);
              return { ...session, drill_count: 0, total_duration: 0, resources_count: 0 };
            }
          })
        );

        setSessions(sessionsWithDetails);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        message.error("Failed to load sessions.");
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const columns = [
    {
      title: "Session",
      dataIndex: "session_name",
      key: "session_name",
      render: (text, record) => <Link to={`/sessions/${record.session_id}`}>{text}</Link>,
    },
    {
      title: "Goals",
      dataIndex: "goals",
      key: "goals",
      render: (goals) => (
        <div
          dangerouslySetInnerHTML={{
            __html: goals.replace(/\n/g, "<br />"),
          }}
        />
      ),
    },
    {
      title: "Resources",
      key: "resources",
      render: (text, record) =>
        record.resources_count ? `${record.resources_count} Resources` : "No Resources",
    },
    {
      title: "Drills",
      dataIndex: "drill_count",
      key: "drills",
      render: (count) => `${count || 0} Drills`, // Default to 0 if undefined
    },
    {
      title: "Total Duration",
      dataIndex: "total_duration",
      key: "total_duration",
      render: (duration) => `${duration || 0} min`, // Default to 0 if undefined
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => navigate(`/sessions/${record.session_id}`)}>View</Menu.Item>
            <Menu.Item onClick={() => handleEditSession(record)}>Edit</Menu.Item>
            <Menu.Item onClick={() => handleDeleteSession(record)}>Delete</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const handleCreateOrEditSession = async (values) => {
    setIsSubmitting(true); // Set submitting state to true
    const sessionData = {
      session_name: values.session_name,
      goals: values.goals,
    };

    try {
      if (isEditMode && editingSession) {
        console.log('Updating session with ID:', editingSession.session_id); 
        await updateSession(editingSession.session_id, sessionData);
        setSessions((prevSessions) =>
          prevSessions.map((session) =>
            session.session_id === editingSession.session_id ? { ...session, ...sessionData } : session
          )
        );
        message.success("Session updated successfully");
      } else {
        const response = await createSession(sessionData);
        setSessions((prevSessions) => [
          ...prevSessions,
          { ...response.data, drill_count: 0, total_duration: 0 }, // Set default values for new session
        ]);
        message.success("Session created successfully");
      }
      setIsModalVisible(false);
      form.resetFields();
      setIsEditMode(false);
      setEditingSession(null);
    } catch (error) {
      console.error("Error creating/updating session:", error);
      message.error("Failed to save session.");
    }finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  const handleEditSession = (session) => {
    setEditingSession(session);
    setIsEditMode(true);
    form.setFieldsValue({
      session_name: session.session_name,
      date: dayjs(session.date),
      start_time: dayjs(session.start_time, "HH:mm"),
      end_time: dayjs(session.end_time, "HH:mm"),
      goals: session.goals,
    });
    setIsModalVisible(true);
  };

  const handleDeleteSession = (session) => {
    Modal.confirm({
      title: "Are you sure you want to delete this session?",
      icon: <ExclamationCircleOutlined />,
      content: `Session: ${session.session_name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteSession(session.session_id);
          setSessions((prevSessions) =>
            prevSessions.filter((s) => s.session_id !== session.session_id)
          );
          message.success("Session deleted successfully");
        } catch (error) {
          console.error("Error deleting session:", error);
          message.error("Failed to delete session.");
        }
      },
    });
  };

  return (
    <div>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} style={{ marginBottom: 16 }}>
        Create Session
      </Button>
      <Table
        columns={columns}
        dataSource={sessions}
        rowKey="session_id"
        loading={loading}
        pagination={{ pageSize: 10 }}
      />

      {/* Create/Edit Session Modal */}
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditMode(false);
          setEditingSession(null);
          form.resetFields();
        }}
        onOk={() => {
          if (!isSubmitting) {
            form.submit();
          }
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleCreateOrEditSession}>
          <Form.Item
            name="session_name"
            label="Session Name"
            rules={[{ required: true, message: "Please enter the session name" }]}
          >
            <Input placeholder="Enter Session Name" />
          </Form.Item>
          <Form.Item name="goals" label="Goals">
            <Input.TextArea placeholder="Enter Goals" rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Sessions;
