// src/pages/Catalog.jsx

import React, { useState, useEffect } from "react";
import {
  Card,
  Tag,
  Select,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Row,
  Col,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import videosData from "../../data/videos";
import "./Catalog.css"; // Optional: For additional styling

const { Option } = Select;

// Helper function to capitalize the first letter
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// Predefined colors for tags
const tagColors = {
  tactical: "geekblue",
  offensive: "volcano",
  overlapping: "green",
  "through balls": "blue",
  defensive: "red",
  pressing: "purple",
  "zonal marking": "orange",
  possession: "cyan",
  "keep away": "lime",
  control: "magenta",
  technique: "gold",
  dribbling: "geekblue",
  fundamentals: "purple",
  passing: "volcano",
  accuracy: "green",
  "warm-up": "blue",
  stretching: "orange",
  dynamic: "cyan",
  agility: "magenta",
  ladder: "gold",
  conditioning: "lime",
  intervals: "geekblue",
  running: "volcano",
  shuttle: "green",
  speed: "purple",
  endurance: "orange",
  stamina: "cyan",
  "one-two": "magenta",
  "man marking": "gold",
  "triangle passing": "lime",
  teamwork: "geekblue",
  shooting: "volcano",
  ball: "green",
  touch: "purple",
};

// Initial videos data
const initialVideos = videosData;

const CatalogPage = () => {
  const [videos, setVideos] = useState(initialVideos);
  const [filteredVideos, setFilteredVideos] = useState(initialVideos);
  const [drillTypes, setDrillTypes] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [filterDrillType, setFilterDrillType] = useState([]);
  const [filterAgeGroup, setFilterAgeGroup] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Extract unique drill types and age groups for filters
  useEffect(() => {
    const uniqueDrillTypes = [
      ...new Set(initialVideos.map((video) => video.drillType)),
    ];
    const uniqueAgeGroups = [
      ...new Set(initialVideos.map((video) => video.ageGroup)),
    ];
    setDrillTypes(uniqueDrillTypes);
    setAgeGroups(uniqueAgeGroups);
  }, []);

  // Handle filtering
  useEffect(() => {
    let tempVideos = [...videos];

    if (filterDrillType.length > 0) {
      tempVideos = tempVideos.filter((video) =>
        filterDrillType.includes(video.drillType),
      );
    }

    if (filterAgeGroup.length > 0) {
      tempVideos = tempVideos.filter((video) =>
        filterAgeGroup.includes(video.ageGroup),
      );
    }

    setFilteredVideos(tempVideos);
  }, [filterDrillType, filterAgeGroup, videos]);

  // Handle Add Drill Modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handle form submission
  const onFinish = (values) => {
    const newVideo = {
      id: videos.length + 1,
      title: values.title,
      url: values.url,
      ageGroup: values.ageGroup,
      drillType: values.drillType,
      tags: values.tags.split(",").map((tag) => tag.trim()),
    };
    setVideos([newVideo, ...videos]);
    message.success("New drill added successfully!");
    setIsModalVisible(false);
  };

  // Handle video hover to play
  const [hoveredVideoId, setHoveredVideoId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredVideoId(id);
  };

  const handleMouseLeave = () => {
    setHoveredVideoId(null);
  };

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Select
          mode="multiple"
          allowClear
          placeholder="Filter by Drill Type"
          style={{ width: 300 }}
          onChange={(value) => setFilterDrillType(value)}
        >
          {drillTypes.map((type) => (
            <Option key={type} value={type}>
              {capitalize(type)}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          allowClear
          placeholder="Filter by Age Group"
          style={{ width: 200 }}
          onChange={(value) => setFilterAgeGroup(value)}
        >
          {ageGroups.map((age) => (
            <Option key={age} value={age}>
              {age}
            </Option>
          ))}
        </Select>
        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
          Add Drill
        </Button>
      </Space>
      <Row gutter={[16, 16]}>
        {filteredVideos.map((video) => (
          <Col xs={24} sm={12} md={8} lg={6} key={video.id}>
            <Card
              hoverable
              cover={
                hoveredVideoId === video.id ? (
                  <div
                    onMouseEnter={() => handleMouseEnter(video.id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${extractYouTubeID(
                        video.url,
                      )}?autoplay=1&mute=1&controls=0`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div
                    onMouseEnter={() => handleMouseEnter(video.id)}
                    onMouseLeave={handleMouseLeave}
                    style={{ height: 200, backgroundColor: "#000" }}
                  >
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${extractYouTubeID(
                        video.url,
                      )}?controls=0`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )
              }
            >
              <Card.Meta title={video.title} />
              <div style={{ marginTop: 10 }}>
                {video.tags.map((tag) => (
                  <Tag
                    key={tag}
                    color={tagColors[tag.toLowerCase()] || "default"}
                  >
                    {capitalize(tag)}
                  </Tag>
                ))}
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Add Drill Modal */}
      <Modal
        title="Add New Drill"
        open={isModalVisible} // Replaced 'visible' with 'open'
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="title"
            label="Drill Title"
            rules={[
              { required: true, message: "Please enter the drill title" },
            ]}
          >
            <Input placeholder="Enter drill title" />
          </Form.Item>
          <Form.Item
            name="url"
            label="YouTube URL"
            rules={[
              { required: true, message: "Please enter the YouTube URL" },
              { type: "url", message: "Please enter a valid URL" },
            ]}
          >
            <Input placeholder="Enter YouTube URL" />
          </Form.Item>
          <Form.Item
            name="ageGroup"
            label="Age Group"
            rules={[{ required: true, message: "Please select the age group" }]}
          >
            <Select placeholder="Select Age Group">
              <Option value="U14">U14</Option>
              <Option value="U16">U16</Option>
              <Option value="U18">U18</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="drillType"
            label="Drill Type"
            rules={[
              { required: true, message: "Please select the drill type" },
            ]}
          >
            <Select placeholder="Select Drill Type">
              <Option value="Offensive Tactical Drills">
                Offensive Tactical Drills
              </Option>
              <Option value="Defensive Tactical Drills">
                Defensive Tactical Drills
              </Option>
              <Option value="Possession Drills">Possession Drills</Option>
              <Option value="Technique Drills">Technique Drills</Option>
              <Option value="Warm-ups">Warm-ups</Option>
              <Option value="Conditioning">Conditioning</Option>
              {/* Add more drill types as needed */}
            </Select>
          </Form.Item>
          <Form.Item
            name="tags"
            label="Tags"
            rules={[
              { required: true, message: "Please enter at least one tag" },
            ]}
          >
            <Input placeholder="Enter tags separated by commas (e.g., tactical, offensive)" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Add Drill
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

// Helper function to extract YouTube video ID from URL
const extractYouTubeID = (url) => {
  const regex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : "";
};

CatalogPage.propTypes = {
  // No props are being passed to Catalog component in this implementation
};

export default CatalogPage;
