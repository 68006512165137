// src/api/sessions.js

import api from '../../../config/api';

// Get all sessions
export const getSessions = () => api.get('/api/sessions');

// Get a single session by ID
export const getSessionById = (sessionId) => api.get(`/api/sessions/${sessionId}`);

// Create a new session
export const createSession = (sessionData) => api.post('/api/sessions', sessionData);

// Update a session by ID
export const updateSession = (sessionId, sessionData) => api.put(`/api/sessions/${sessionId}`, sessionData);

// Delete a session by ID
export const deleteSession = (sessionId) => api.delete(`/api/sessions/${sessionId}`);

// Get drills for a session
export const getSessionDrills = (sessionId) => api.get(`/api/session-drills/${sessionId}/drills`);

// Get total duration for a session
export const getSessionDuration = (sessionId) => api.get(`/api/session-drills/${sessionId}/duration`);

// Get available drills not yet added to a specific session's plan
export const getAvailableDrills = (sessionId) => api.get(`/api/session-drills/${sessionId}/drills/available`);

// Add a new drill to a session's plan
export const createSessionDrill = (sessionId, drillData) =>
  api.post(`/api/session-drills/${sessionId}/drills`, drillData);

// Create a new drill
export const createDrill = (drillData) => api.post('/api/drills', drillData);

// Update an existing drill by ID
export const updateDrill = (drill_id, drillData) => api.put(`/api/drills/${drill_id}`, drillData);

// Delete an drill by ID
export const deleteDrill = (drill_id) => api.delete(`/api/drills/${drill_id}`);

// Delete a sessions drill by ID
export const deleteSessionDrill = (session_id, drill_id) => api.delete(`/api/session-drills/${session_id}/drills/${drill_id}`);

export const uploadSessionFile = (sessionId, formData) =>
  api.post(`/api/sessions/${sessionId}/files`, formData);

// Get all files for a session
export const getSessionFiles = (sessionId) =>
  api.get(`/api/sessions/${sessionId}/files`);

// Delete a file from a session
export const deleteSessionFile = (fileId) =>
  api.delete(`/api/sessions/files/${fileId}`);

export const uploadDrillFile = (drillId, formData) =>
  api.post(`/api/drills/${drillId}/files`, formData);

// Get all drill files for a specific drill
export const getAllDrillFiles = (drillId) =>
  api.get(`/api/drills/${drillId}/files`);

export const getDrillFileById = (drillId, fileId) => {
  console.log(`/api/drills/${drillId}/files/${fileId}`);
  return api.get(`/api/drills/${drillId}/files/${fileId}`)
    .then(response => {
      console.log('Returning drill file:', response.data);
      return response.data.file_url;
    })
    .catch(error => {
      console.error('Error in getDrillFileById:', error);
      throw error;
    });
}

// Delete a file from a session
export const deleteDrillFile = (fileId) =>
  api.delete(`/api/drills/files/${fileId}`);

export const getSessionFileById = (sessionId, fileId) => {
  console.log(`/api/sessions/${sessionId}/files/${fileId}`);
  return api.get(`/api/sessions/${sessionId}/files/${fileId}`)
    .then(response => {
      console.log('Returning session file:', response.data);
      return response.data.file_url;
    })
    .catch(error => {
      console.error('Error in getSessionFileById:', error);
      throw error;
    });
};

export const getUserById = (userId) => api.get(`/api/users/${userId}`);
