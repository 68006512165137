// src/pages/Session/Notes.jsx

import React from "react";
import NotesComponent from "../../../components/Notes/NotesComponent";

const Notes = ({ sessionId }) => {
  return (
    <div>
      <NotesComponent entityId={sessionId} entityType="session" displayType="table" />
    </div>
  );
};

export default Notes;



