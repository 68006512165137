import React, { useState } from 'react';
import { message, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthForm from './components/AuthForm';
import { login } from '../../hooks/useAuth';
import logo from "../../assets/logo.jpg"; // Adjust the path as necessary

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ email, password }) => {
    setLoading(true);
    const { error } = await login(email, password);
    setLoading(false);

    if (error) {
      message.error(error.message);
    } else {
      message.success('Login successful');
      navigate('/');
    }
  };

  const goToSignup = () => {
    navigate('/signup');
  };

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      {/* Logo */}
      <div style={{ marginBottom: '20px' }}>
        <img src={logo} alt="Logo" style={{ width: '150px' }} />
      </div>
      <h2>Login</h2>
      <Spin spinning={loading}>
        <AuthForm onSubmit={handleLogin} buttonText="Log in" />
      </Spin>
      <Button type="link" onClick={goToSignup} style={{ marginTop: '1rem' }}>
        Don't have an account? Sign Up
      </Button>
    </div>
  );
};

export default Login;