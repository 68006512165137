import React from "react";
import { Modal, Descriptions } from "antd";

const InjuryDetailsModal = ({ visible, onClose, injury }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: '20px' }}
    >
      <Descriptions bordered column={1} size="small">
        <Descriptions.Item label="Injury Description">
          {injury.injury_description || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Management">
          {injury.management || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Follow-up">
          {injury.follow_up ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Submitted By">
          {injury.submitted_by || "N/A"}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default InjuryDetailsModal;