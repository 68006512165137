import React, { useState, useEffect } from "react";
import {
  Card,
  List,
  Input,
  Button,
  Checkbox,
  Space,
  Typography,
  message,
  Empty,
} from "antd";
import {
  CheckCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { getTasks, createTask, updateTask, deleteTask } from "../api/home";

const { Text } = Typography;

const TodoList = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await getTasks();
      const tasksWithId = response.data.map((task) => ({
        ...task,
        id: task.task_id, // Ensure task_id is unique
      }));
      console.log("Fetched tasks with IDs:", tasksWithId);
      setTasks(tasksWithId);
    } catch (error) {
      message.error("Failed to fetch tasks!");
    }
  };

  const addTask = async () => {
    if (newTask.trim() === "") {
      message.error("Task cannot be empty!");
      return;
    }
    const newTaskItem = {
      title: newTask.trim(),
      completed: false,
    };
    try {
      await createTask(newTaskItem);
      setNewTask("");
      message.success("Task added!");
      fetchTasks();
    } catch (error) {
      message.error("Failed to add task!");
    }
  };

  const toggleTaskCompletion = async (id, newCompleted) => {
    try {
      // Optimistically update the task's completed status
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === id ? { ...task, completed: newCompleted } : task
        )
      );

      // Send the update to the server
      await updateTask(id, { completed: newCompleted });

      // Optionally re-fetch tasks to sync with the server
      // fetchTasks();
    } catch (error) {
      message.error("Failed to update task!");

      // Revert the optimistic update if API call fails
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === id ? { ...task, completed: !newCompleted } : task
        )
      );
    }
  };

  const deleteTaskById = async (id) => {
    try {
      await deleteTask(id);
      // Remove the task from the local state
      setTasks((prevTasks) => prevTasks.filter((task) => task.id !== id));
      message.success("Task deleted!");
    } catch (error) {
      message.error("Failed to delete task!");
    }
  };

  return (
    <Card
      title={
        <span>
          <CheckCircleOutlined style={{ marginRight: "8px" }} />
          To do List
        </span>
      }
      bordered={false}
      
    >
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Input
          placeholder="Add a new task"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          onPressEnter={addTask}
          suffix={
            <Button type="primary" onClick={addTask} icon={<PlusOutlined />} />
          }
        />
        {tasks.length === 0 ? (
          <Empty
            description="No Tasks"
            image={
              <FileDoneOutlined style={{ fontSize: 48, color: "gray" }} />
            }
            imageStyle={{ height: 60 }}
          />
        ) : (
          <div
            style={{ 
              height: "30vh", 
              overflowY: "auto" 
              }} 
          >
          <List
            dataSource={tasks}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    type="text"
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    onClick={() => deleteTaskById(item.id)}
                  />,
                ]}
                style={{
                  backgroundColor: "#fafafa",
                  borderRadius: "4px",
                  marginBottom: "8px",
                  padding: "8px 12px",
                }}
              >
                <Checkbox
                  checked={item.completed}
                  onChange={(e) =>
                    toggleTaskCompletion(item.id, e.target.checked)
                  }
                >
                  <Text
                    style={{
                      marginLeft: "8px",
                      color: item.completed ? "gray" : "inherit",
                      textDecoration: item.completed
                        ? "line-through"
                        : "none",
                    }}
                  >
                    {item.title}
                  </Text>
                </Checkbox>
              </List.Item>
            )}
          />
          </div>
        )}
      </Space>
    </Card>
  );
};

export default TodoList;
