// src/data/videos.js

const videos = [
    {
      id: 1,
      title: "Offensive Tactical Drill: Overlapping Runs",
      url: "https://www.youtube.com/watch?v=BxoPFfdgvLA&t=79s&ab_channel=APFC.POSITIONALPLAY",
      ageGroup: "U14",
      drillType: "Offensive Tactical Drills",
      tags: ["tactical", "offensive", "overlapping"],
    },
    {
      id: 2,
      title: "Offensive Tactical Drill: Through Balls",
      url: "https://www.youtube.com/watch?v=BxoPFfdgvLA&t=79s&ab_channel=APFC.POSITIONALPLAY",
      ageGroup: "U16",
      drillType: "Offensive Tactical Drills",
      tags: ["tactical", "offensive", "through balls"],
    },
    {
      id: 3,
      title: "Defensive Tactical Drill: Pressing",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U14",
      drillType: "Defensive Tactical Drills",
      tags: ["tactical", "defensive", "pressing"],
    },
    {
      id: 4,
      title: "Defensive Tactical Drill: Zonal Marking",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U16",
      drillType: "Defensive Tactical Drills",
      tags: ["tactical", "defensive", "zonal marking"],
    },
    {
      id: 5,
      title: "Possession Drill: Keep Away",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U14",
      drillType: "Possession Drills",
      tags: ["possession", "keep away", "control"],
    },
    {
      id: 6,
      title: "Possession Drill: Rondo",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U16",
      drillType: "Possession Drills",
      tags: ["possession", "rondo", "control"],
    },
    {
      id: 7,
      title: "Technique Drill: Dribbling Basics",
      url: "https://www.youtube.com/watch?v=IJv_1BWplO0&ab_channel=Onside-Training",
      ageGroup: "U14",
      drillType: "Technique Drills",
      tags: ["technique", "dribbling", "fundamentals"],
    },
    {
      id: 8,
      title: "Technique Drill: Passing Accuracy",
      url: "https://www.youtube.com/watch?v=IJv_1BWplO0&ab_channel=Onside-Training",
      ageGroup: "U16",
      drillType: "Technique Drills",
      tags: ["technique", "passing", "accuracy"],
    },
    {
      id: 9,
      title: "Warm-up: Dynamic Stretching Routine",
      url: "https://www.youtube.com/watch?v=IJv_1BWplO0&ab_channel=Onside-Training",
      ageGroup: "U14",
      drillType: "Warm-ups",
      tags: ["warm-up", "stretching", "dynamic"],
    },
    {
      id: 10,
      title: "Warm-up: Agility Ladder Drills",
      url: "https://www.youtube.com/watch?v=IJv_1BWplO0&ab_channel=Onside-Training",
      ageGroup: "U16",
      drillType: "Warm-ups",
      tags: ["warm-up", "agility", "ladder"],
    },
    {
      id: 11,
      title: "Conditioning: Interval Running",
      url: "https://www.youtube.com/watch?v=OZoIz44Dy18&ab_channel=360Player",
      ageGroup: "U14",
      drillType: "Conditioning",
      tags: ["conditioning", "intervals", "running"],
    },
    {
      id: 12,
      title: "Conditioning: Shuttle Runs",
      url: "https://www.youtube.com/watch?v=OZoIz44Dy18&ab_channel=360Player",
      ageGroup: "U16",
      drillType: "Conditioning",
      tags: ["conditioning", "shuttle runs", "speed"],
    },
    {
      id: 13,
      title: "Offensive Tactical Drill: One-Two Passing",
      url: "https://www.youtube.com/watch?v=BxoPFfdgvLA&t=79s&ab_channel=APFC.POSITIONALPLAY",
      ageGroup: "U18",
      drillType: "Offensive Tactical Drills",
      tags: ["tactical", "offensive", "one-two"],
    },
    {
      id: 14,
      title: "Defensive Tactical Drill: Man-to-Man Marking",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U18",
      drillType: "Defensive Tactical Drills",
      tags: ["tactical", "defensive", "man marking"],
    },
    {
      id: 15,
      title: "Possession Drill: Triangle Passing",
      url: "https://www.youtube.com/watch?v=DfsY9sf-gc4&t=19s&ab_channel=PaulSpacey",
      ageGroup: "U18",
      drillType: "Possession Drills",
      tags: ["possession", "triangle passing", "teamwork"],
    },
    {
      id: 16,
      title: "Technique Drill: Shooting Accuracy",
      url: "https://www.youtube.com/watch?v=IJv_1BWplO0&ab_channel=Onside-Training",
      ageGroup: "U18",
      drillType: "Technique Drills",
      tags: ["technique", "shooting", "accuracy"],
    },
    {
      id: 17,
      title: "Warm-up: Ball Control Exercises",
      url: "https://www.youtube.com/watch?v=OZoIz44Dy18&ab_channel=360Player",
      ageGroup: "U18",
      drillType: "Warm-ups",
      tags: ["warm-up", "ball control", "touch"],
    },
    {
      id: 18,
      title: "Conditioning: Endurance Training",
      url: "https://www.youtube.com/watch?v=OZoIz44Dy18&ab_channel=360Player",
      ageGroup: "U18",
      drillType: "Conditioning",
      tags: ["conditioning", "endurance", "stamina"],
    },
  ];
  
  export default videos;