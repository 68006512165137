// src/components/EventModal.jsx

import React, { useState, useEffect } from "react";
import { Modal, Button, Descriptions, message } from "antd";
import EventForm from "./EventForm";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getTeamName, deleteEvent, updateEvent } from '../api/events';
import { getSessionById } from "../../Sessions/api/sessions"; 
import { Link } from "react-router-dom";

dayjs.extend(utc);

const EventModal = ({ event, visible, onClose, onDelete, onUpdate }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteTriggered, setDeleteTriggered] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [sessionName, setSessionName] = useState("");

  useEffect(() => {
    const fetchTeamName = async () => {
      if (event?.team_id) {
        try {
          const response = await getTeamName(event.team_id);
          setTeamName(response.data.team_name || "N/A");
        } catch (error) {
          console.error("Error fetching team name:", error);
          setTeamName("N/A");
        }
      }
    };

    const fetchSessionName = async () => {
      if (event?.entity_type === "Session" && event.entity_id) {
        try {
          const response = await getSessionById(event.entity_id);
          setSessionName(response.data.session_name || "N/A");
        } catch (error) {
          console.error("Error fetching session name:", error);
          setSessionName("N/A");
        }
      }
    };

    fetchTeamName();
    fetchSessionName();
  }, [event]);

  const handleDelete = async () => {
    if (deleteTriggered) return;
    setDeleteTriggered(true);

    try {
        await deleteEvent(event.event_id);
        message.success("Event deleted successfully!");

        // Inform parent component about deletion to remove the modal and update UI
        onDelete(event.event_id);
        
        // Close the modal
        onClose();

    } catch (error) {
        console.error("Error deleting event:", error);
        message.error("Failed to delete event.");
    } finally {
        setDeleteTriggered(false);
    }
  };

  const handleEdit = async (updatedEventData) => {
    try {
      const response = await updateEvent(event.event_id, updatedEventData);
      message.success("Event updated successfully!");
      onUpdate(response.data);
      setIsEditMode(false);
    } catch (error) {
      console.error("Error updating event:", error);
      message.error("Failed to update event.");
    }
  };

  const formatDate = (date) => dayjs.utc(date).format("YYYY-MM-DD");
  const formatTime = (time) => (time ? dayjs(time, "HH:mm:ss").format("HH:mm") : "N/A");

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setIsEditMode(false);
        onClose();
      }}
      footer={
        isEditMode
          ? null
          : [
              <Button key="edit" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>,
              <Button key="close" onClick={onClose}>
                Close
              </Button>,
              <Button key="delete" type="primary" danger onClick={handleDelete}>
              Delete
             </Button>,
            ]
      }
      width={800}
      destroyOnClose
      bodyStyle={{ padding: '20px' }}
    >
      {event ? (
        isEditMode ? (
          <EventForm initialValues={event} onSave={handleEdit} onCancel={() => setIsEditMode(false)} />
        ) : (
          <div>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Event Name">{event.event_name}</Descriptions.Item>
              <Descriptions.Item label="Event Type">{event.entity_type}</Descriptions.Item>

              {event.entity_type === "Session" && event.entity_id ? (
                <Descriptions.Item label="Session">
                  {(() => {
                    const sessionUrl = `/sessions/${event.entity_id}`;
                    console.log("Session URL:", sessionUrl);
                    return (
                      <Link to={sessionUrl}>
                        {sessionName}
                      </Link>
                    );
                  })()}
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item label="Team Name">{teamName}</Descriptions.Item>
              <Descriptions.Item label="Date">{formatDate(event.date)}</Descriptions.Item>
              <Descriptions.Item label="Start Time">{formatTime(event.start_time)}</Descriptions.Item>
              <Descriptions.Item label="End Time">{formatTime(event.end_time)}</Descriptions.Item>
              <Descriptions.Item label="Location">{event.location || "N/A"}</Descriptions.Item>
            </Descriptions>

          </div>
        )
      ) : (
        <p>No event selected.</p>
      )}
    </Modal>
  );
};

EventModal.propTypes = {
  event: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EventModal;
