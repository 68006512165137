import api from '../../../config/api';

// Get all upcoming events
export const getUpcomingEvents = () => api.get('/api/events/upcoming');

// Get all current injuries
export const getCurrentInjuries = () => api.get('/api/injuries');

// Get all tasks
export const getTasks = () => api.get('/api/tasks');

// Create a new task
export const createTask = (taskData) => api.post('/api/tasks', taskData);

// Update a task by ID
export const updateTask = (taskId, taskData) => api.put(`/api/tasks/${taskId}`, taskData);

// Delete a task by ID
export const deleteTask = (taskId) => api.delete(`/api/tasks/${taskId}`);

// Get all teams
export const getTeams = () => api.get('/api/teams');

// Get player details by ID
export const getPlayerDetails = (playerId) => api.get(`/api/players/${playerId}`);

// Get team details by ID
export const getTeamDetails = (teamId) => api.get(`/api/teams/${teamId}`);
