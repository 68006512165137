// src/api/attendance.js

import api from '../../../config/api';  

// Fetch events data
export const fetchEvents = () => api.get('/api/events');

// Fetch teams data
export const fetchTeams = () => api.get('/api/teams');

// Fetch players data
export const fetchPlayers = () => api.get('/api/players');

// Fetch players for a specific team
export const fetchPlayersByTeam = (teamId) => api.get(`/api/teams/${teamId}/players`);

// Fetch attendance data for a specific event
export const fetchAttendanceByEvent = (eventId) => api.get(`/api/attendance/event/${eventId}`);

// Update attendance data for a specific event
export const updateAttendanceByEvent = (eventId, attendanceData) => 
    api.patch(`/api/attendance/event/${eventId}`, attendanceData);