import React from "react";
import { Layout, Menu, Tooltip } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  ScheduleOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/logo.jpg";

const { Sider } = Layout;

const SideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getSelectedKey = () => {
    const path = location.pathname;
    if (path.startsWith("/attendance")) return "attendance";
    if (path.startsWith("/teams")) return "teams";
    if (path.startsWith("/sessions")) return "sessions";
    if (path.startsWith("/calendar")) return "calendar";
    if (path.startsWith("/settings")) return "settings";
    return "home";
  };

  const handleMenuClick = (e) => {
    navigate(e.key === "home" ? "/" : `/${e.key}`);
  };

  const menuItems = [
    {
      key: "home",
      icon: (
        <Tooltip placement="right" title="Home">
          <HomeOutlined />
        </Tooltip>
      ),
    },
    {
      key: "teams",
      icon: (
        <Tooltip placement="right" title="Teams">
          <TeamOutlined />
        </Tooltip>
      ),
    },
    {
      key: "sessions",
      icon: (
        <Tooltip placement="right" title="Sessions">
          <ScheduleOutlined />
        </Tooltip>
      ),
    },
    {
      key: "calendar",
      icon: (
        <Tooltip placement="right" title="Calendar">
          <CalendarOutlined />
        </Tooltip>
      ),
    },
    {
      key: "attendance",
      icon: (
        <Tooltip placement="right" title="Attendance">
          <CheckCircleOutlined />
        </Tooltip>
      ),
    },
  ];

  const settingsMenuItem = [
    {
      key: "settings",
      icon: (
        <Tooltip placement="right" title="Settings">
          <SettingOutlined />
        </Tooltip>
      ),
    }
  ];

  return (
    <Sider
      style={{
        background: "#fff",
        borderRight: "1px solid #f0f0f0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
      width={80}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* Logo */}
        <div style={{ padding: "10px", textAlign: "center" }}>
          <img src={logo} alt="Logo" style={{ width: "65px" }} />
        </div>

        {/* Top Menu */}
        <Menu
          mode="inline"
          selectedKeys={[getSelectedKey()]}
          onClick={handleMenuClick}
          style={{ borderRight: 0, flex: 1 }}
          items={menuItems}
        />
        {/* Bottom Menu */}
        <Menu
          mode="inline"
          selectedKeys={[getSelectedKey()]}
          onClick={handleMenuClick}
          style={{ borderRight: 0 }}
          items={settingsMenuItem}
        />
      </div>
    </Sider>
  );
};

export default SideNav;