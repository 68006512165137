import React, { useEffect, useState } from "react";
import { Modal, Button, message, Table, Dropdown, Menu,Tag } from "antd";
import { EllipsisOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import InjuryForm from "./forms/InjuryForm";
import {
  createInjury,
  deleteInjury,
  getInjuriesByTeamId,
  getInjuryById,
  updateInjury,
} from "../api/teams";
import InjuryDetailsModal from "./InjuryDetailsModal"; // Import the InjuryDetailsModal component

const InjuryPage = ({ teamId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [injuries, setInjuries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingInjury, setEditingInjury] = useState(null); // Track the injury being edited
  const [selectedInjury, setSelectedInjury] = useState(null); // State to track selected injury for details

  // Fetch injuries for the selected team
  const fetchInjuries = async () => {
    try {
      const response = await getInjuriesByTeamId(teamId);
      console.log("Fetched injuries:", response.data); // Log fetched injuries


      if (response.data.length === 0) {
        message.info("No injuries found for the selected team.");
      }

      setInjuries(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching injuries:", error);
      // message.error("Failed to load injuries data.");
      setLoading(false); // Ensure loading is set to false even on error
    }
  };

  useEffect(() => {
    fetchInjuries();
  }, [teamId]);

  // Handle saving the form data
  const handleSave = async (formData) => {
    try {
      if (editingInjury) {
        console.log("Updating injury with data:", formData); // Log data being submitted for update
        await updateInjury(editingInjury.injury_id, formData);
        message.success("Injury report updated successfully!");
      } else {
        console.log("Creating new injury with data:", formData); // Log data being submitted for creation
        await createInjury(formData);
        message.success("Injury report submitted successfully!");
      }
      setIsModalVisible(false);
      setEditingInjury(null); // Clear editing state
      fetchInjuries(); // Refresh data
    } catch (error) {
      console.error("Error submitting injury report:", error);
      message.error("Failed to submit injury report.");
    }
  };

  // Handle editing an injury
  const handleEdit = async (injuryId) => {
    try {
      const response = await getInjuryById(injuryId);
      console.log("Fetched injury data for editing:", response.data); // Log the data fetched from the server
      setEditingInjury(response.data); // Set the injury data for editing
      setIsModalVisible(true); // Open the modal with pre-filled data
    } catch (error) {
      console.error("Error fetching injury data for editing:", error);
      message.error("Failed to load injury data for editing.");
    }
  };

  // Handle deleting an injury
  const handleDelete = (injuryId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this injury report?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          console.log("Deleting injury with ID:", injuryId); // Log the ID of the injury being deleted
          await deleteInjury(injuryId);
          message.success("Injury report deleted successfully!");

          // Update the injuries state by filtering out the deleted injury
          setInjuries((prevInjuries) =>
            prevInjuries.filter((injury) => injury.injury_id !== injuryId)
          );
        } catch (error) {
          console.error("Error deleting injury report:", error);
          message.error("Failed to delete injury report.");
        }
      },
    });
  };

  const columns = [
    {
      title: "Player Name",
      dataIndex: "player_name",
      key: "player_name",
      render: (text, record) => (
        <a onClick={() => setSelectedInjury(record)}>{text}</a>
      ),
    },
    {
      title: "Injury Date",
      dataIndex: "date",
      key: "date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Injury Type",
      dataIndex: "injury_type",
      key: "injury_type",
    },
    {
      title: "Injury Status",
      dataIndex: "injury_status",
      key: "injury_status",
      render: (injuryStatus) => {
        console.log("Injury Status:", injuryStatus);
        if (!injuryStatus || typeof injuryStatus !== "string") {
          return "Unknown"; // Fallback if injuryStatus is not valid
        }
      
        // Function to capitalize the first letter of each word
        const capitalizeWords = (str) => {
          return str
            .split(" ") // Split the string into words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(" "); // Join them back into a single string
        };
      
        // Return capitalized plain text
        return capitalizeWords(injuryStatus);
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => setSelectedInjury(record)}>View</Menu.Item>
            <Menu.Item onClick={() => handleEdit(record.key)}>Edit</Menu.Item>
            <Menu.Item onClick={() => handleDelete(record.key)}>Delete</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="text" icon={<EllipsisOutlined rotate={90} />} />
          </Dropdown>
        );
      },
    },
  ];

  const tableData = injuries.map((injury) => ({
    key: injury.injury_id,
    player_name: injury.player_name,
    date: injury.date,
    injury_description: injury.injury_description,
    management: injury.management,
    follow_up: injury.follow_up,
    injury_type: injury.injury_type,
    injury_status: injury.injury_status,
    submitted_by: injury.submitted_by, 
  }));

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          console.log("Opening form for new injury report");
          setEditingInjury(null); // Clear any previous editing data
          setIsModalVisible(true);
        }}
        style={{ marginBottom: 16 }}
      >
        Report Injury
      </Button>

      {/* Table to display injuries data */}
      <Table
        columns={columns}
        dataSource={tableData}
        loading={loading}
        pagination={{ pageSize: 5 }}
        rowKey="key"
      />

      {/* Modal with Injury Form */}
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <InjuryForm
          teamId={teamId}
          initialValues={editingInjury} // Pass the injury data for editing if available
          onSave={handleSave}
          onCancel={() => setIsModalVisible(false)}
        />
      </Modal>

      {/* Modal with Injury Details */}
      {selectedInjury && (
        <InjuryDetailsModal
          visible={!!selectedInjury}
          onClose={() => setSelectedInjury(null)}
          injury={selectedInjury}
        />
      )}
    </div>
  );
};

export default InjuryPage;
