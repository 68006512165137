// src/pages/HomePage.jsx

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import TodoList from "./components/TodoList";
import UpcomingEvents from "./components/UpcomingEvents";
import CurrentInjuries from "./components/CurrentInjuries";
import { getUpcomingEvents, getCurrentInjuries, getTeams } from "./api/home";

const Home = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [injuries, setInjuries] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    // Fetch upcoming events
    getUpcomingEvents().then(response => setUpcomingEvents(response.data));

    // Fetch current injuries
    getCurrentInjuries().then(response => setInjuries(response.data));

    // Fetch teams
    getTeams().then(response => setTeams(response.data));
  }, []);


  const handleInjuryClick = (injury) => {
    console.log("Injury clicked:", injury);
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <UpcomingEvents events={upcomingEvents} teams={teams} />
        </Col>
        <Col xs={24} md={12}>
          <CurrentInjuries injuries={injuries} onInjuryClick={handleInjuryClick} />
          <TodoList />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
