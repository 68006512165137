import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  message,
  Form,
} from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getSessionDrills,
  getAllDrillFiles,
  getDrillFileById,
  deleteDrillFile,
  updateDrill,
  uploadDrillFile,
  createSessionDrill,
  createDrill,
  getAvailableDrills,
  deleteSessionDrill,
  getUserById,
  getSessionDuration,
} from '../api/sessions';
import dayjs from "dayjs";
import PreviewModal from "./PreviewModal";
import DrillTable from "./DrillTable";
import FileViewModal from './FileViewModal';
import CustomDrillModal from './CustomDrillModal';
import ExistingDrillModal from './ExistingDrillModal';

const Plan = ({ sessionId, user, setTotalDuration, setDrillCount }) => {
  const [sessionDrills, setSessionDrills] = useState([]);
  const [drillFiles, setDrillFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const [selectedDrill, setSelectedDrill] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState({ fileUrl: '', fileName: '' });
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingDrill, setEditingDrill] = useState(null);
  const [form] = Form.useForm();
  const [availableDrills, setAvailableDrills] = useState([]);
  const [isCustomDrillVisible, setIsCustomDrillVisible] = useState(false);
  const [isExistingDrillVisible, setIsExistingDrillVisible] = useState(false);
  const [addToPlan, setAddToPlan] = useState(false);
  const [drillFilesCount, setDrillFilesCount] = useState({});
  const [selectedDrillDetails, setSelectedDrillDetails] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    const fetchPlanDrills = async () => {
      try {
        const response = await getSessionDrills(sessionId);
        const drills = response.data || [];
        setSessionDrills(drills);

        // Fetch file counts for all drills
        const fileCounts = {};
        for (const drill of drills) {
          const filesResponse = await getAllDrillFiles(drill.drill_id);
          fileCounts[drill.drill_id] = filesResponse.data.length;
        }
        setDrillFilesCount(fileCounts);
      } catch (error) {
        console.error("Error fetching drills:", error);
        if (error.response?.status !== 404) {
          message.error("Failed to fetch drills for this session.");
        }
      }
    };

    if (sessionId) {
      fetchPlanDrills();
    }
  }, [sessionId]);

  const fetchAvailableDrills = async () => {
    console.log(`[fetchAvailableDrills] Starting fetch for available drills with sessionId: ${sessionId}`);
    try {
      const response = await getAvailableDrills(sessionId);
      console.log("[fetchAvailableDrills] API call successful. Response Data:", response.data);
      if (response.data.length > 0) {
        setAvailableDrills(response.data);
      } else {
        console.warn("[fetchAvailableDrills] No more drills available for this session.");
        setAvailableDrills([]); // Set to empty if there are no available drills
      }
    } catch (error) {
      console.error("[fetchAvailableDrills] Error fetching available drills:", error);
      message.error("An error occurred while fetching available drills. Please try again.");
    }
  };

  const handleAddExistingDrills = async (selectedDrills) => {
    try {
      for (const drill of selectedDrills) {
        await createSessionDrill(sessionId, {
          drill_id: drill.drill_id,
          sequence: sessionDrills.length + 1,
        });
      }
      message.success("Selected drills added to the plan successfully.");
      const updatedDrillsResponse = await getSessionDrills(sessionId);
      setSessionDrills(updatedDrillsResponse.data);
      setDrillCount(updatedDrillsResponse.data.length); // Update drill count
      // Optionally, update total duration if needed
      const totalDurationResponse = await getSessionDuration(sessionId);
      setTotalDuration(totalDurationResponse.data.totalDuration || 0); // Update total duration
    } catch (error) {
      console.error("[handleAddExistingDrills] Error adding existing drills to plan:", error);
      message.error("Failed to add existing drills to plan.");
    } finally {
      setIsExistingDrillVisible(false);
      setAvailableDrills([]);
    }
  };

  // Save new or edit existing drill
  const handleSaveDrill = async (drillData) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    try {
      let savedDrill;
      if (editingDrill) {
        // Update the existing drill
        await updateDrill(editingDrill.drill_id, drillData);
        const updatedDrills = sessionDrills.map((drill) =>
          drill.drill_id === editingDrill.drill_id ? { ...drill, ...drillData } : drill
        );
        setSessionDrills(updatedDrills);
        message.success("Drill updated successfully");
        savedDrill = editingDrill; // Use the existing drill for file uploads
      } else {
        // Create a new drill
        const response = await createDrill(drillData);
        savedDrill = response.data;

        // Only add to the plan if the checkbox is checked
        if (addToPlan) {
          await createSessionDrill(sessionId, {
            drill_id: savedDrill.drill_id,
            sequence: sessionDrills.length + 1,
          });
          message.success("Drill added to the plan successfully.");
        } else {
          message.warning("Drill was created but not added to the plan.");
        }

        // Refresh the drills for the session
        const updatedDrillsResponse = await getSessionDrills(sessionId);
        setSessionDrills(updatedDrillsResponse.data);
        setDrillCount(updatedDrillsResponse.data.length); // Update drill count
        // Optionally, update total duration if needed
        const totalDurationResponse = await getSessionDuration(sessionId);
        setTotalDuration(totalDurationResponse.data.totalDuration || 0); // Update total duration
      }

      // Upload files for the drill
      if (fileList.length > 0) {
        const uploadPromises = fileList.map(file => {
          const formData = new FormData();
          formData.append('file', file.originFileObj);
          return uploadDrillFile(savedDrill.drill_id, formData);
        });

        await Promise.all(uploadPromises);
        message.success("Files uploaded successfully.");

        // Update the file count for the drill
        setDrillFilesCount(prevCounts => ({
          ...prevCounts,
          [savedDrill.drill_id]: (prevCounts[savedDrill.drill_id] || 0) + fileList.length
        }));
      }
    } catch (error) {
      console.error("[handleSaveDrill] Error saving custom drill:", error);
      message.error("Failed to save custom drill.");
    } finally {
      // Reset states and form
      setIsSubmitting(false);
      handleCloseCustomDrillModal();
    }
  };

  const fetchDrillFiles = async (drillId) => {
    try {
      const response = await getAllDrillFiles(drillId);
      const files = await Promise.all(response.data.map(async (file) => {
        const url = new URL(file.file_url);
        const fileNameWithToken = url.pathname.split('/').pop();
        const fileName = fileNameWithToken.split('?')[0].replace(/^\d+_/, '');

        // Fetch user details
        let uploadedBy = "Unknown User";
        if (file.uploaded_by) {
          try {
            const userResponse = await getUserById(file.uploaded_by);
            uploadedBy = `${userResponse.data.first_name} ${userResponse.data.last_name}`;
          } catch (error) {
            console.error("Failed to fetch user details:", error);
          }
        }
        return {
          key: file.id.toString(),
          id: file.id,
          name: fileName,
          file_url: file.file_url,
          uploadedAt: dayjs(file.created_at).format("YYYY-MM-DD"),
          uploadedBy: uploadedBy,
        };
      }));
      setDrillFiles(files);

    } catch (error) {
      console.error("Failed to fetch drill files:", error);
      message.error("Failed to load drill files");
    }
  };

  const handleViewFiles = async (drill) => {
    console.log("[handleViewFiles] Drill object received for viewing files:", drill);
    setSelectedDrill(drill);
    console.log("[handleViewFiles] selectedDrill set to:", drill);
    setSelectedDrillDetails({
      name: drill.drill_name,
      description: drill.description,
      duration: drill.duration,
    });
    await fetchDrillFiles(drill.drill_id);
    setIsFileModalVisible(true);
  };

  const handlePreview = async (fileId, fileName) => {
    console.log(`Attempting to preview file ID: ${fileId}, Name: ${fileName}`);
    try {
      console.log('Regenerating signed URL...');
      const newSignedUrl = await getDrillFileById(selectedDrill.drill_id, fileId);
      if (!newSignedUrl) {
        throw new Error("No URL returned");
      }
      console.log('Received new signed URL:', newSignedUrl);
      setPreviewFile({ fileUrl: newSignedUrl, fileName });
      setIsPreviewVisible(true);
    } catch (error) {
      console.error("Failed to regenerate signed URL:", error);
      message.error("Failed to preview file");
    }
  };

  const handleEditDrill = async (drill) => {
    console.log("Editing drill:", drill);
    setEditingDrill(drill);
    console.log("selectedDrill set to:", drill);
    form.setFieldsValue(drill);
    await fetchDrillFiles(drill.drill_id);
    setIsEditModalVisible(true);
    setIsCustomDrillVisible(true);
    console.log("selectedDrill set to:", drill);
  };

  const handleDeleteDrill = (drillId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this drill?",
      icon: <ExclamationCircleOutlined />,
      content: `Drill: ${drillId}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteSessionDrill(sessionId, drillId);
          setSessionDrills(sessionDrills.filter(drill => drill.drill_id !== drillId));
          message.success("Drill deleted successfully");

          // Update drill count
          const updatedDrillsResponse = await getSessionDrills(sessionId);
          setDrillCount(updatedDrillsResponse.data.length); // Update drill count

          // Optionally, update total duration if needed
          const totalDurationResponse = await getSessionDuration(sessionId);
          setTotalDuration(totalDurationResponse.data.totalDuration || 0); // Update total duration
        } catch (error) {
          console.error("Failed to delete drill:", error);
          message.error("Failed to delete drill");
        }
      },
    });
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleRemoveFile = (file) => {
    setFileList((prevList) => prevList.filter(item => item.uid !== file.uid));
  };

  const handleCloseCustomDrillModal = () => {
    setIsCustomDrillVisible(false);
    setEditingDrill(null);
    form.resetFields();
    setAddToPlan(false);
    setFileList([]);
  };

  const handleDeleteFile = async (file, drillId) => {
    console.log("Attempting to delete file:", file);
    console.log("[handleDeleteFile] Current drillId before deletion:", drillId);

    if (!drillId) {
      console.error("Drill ID is null. Cannot proceed with file deletion.");
      return;
    }
    try {
      await deleteDrillFile(file.id);
      setDrillFiles((prevFiles) => prevFiles.filter(item => item.id !== file.id));
      message.success("File deleted successfully");

      // Update the file count for the drill
      setDrillFilesCount((prevCounts) => {
        const currentCount = prevCounts[drillId] || 0;
        return {
          ...prevCounts,
          [drillId]: Math.max(0, currentCount - 1),
        };
      });

    } catch (error) {
      console.error("Failed to delete file:", error);
      message.error("Failed to delete file");
    }
  };


  return (
    <div>
      <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setEditingDrill(null);
            form.resetFields();
            setAddToPlan(false);
            setIsCustomDrillVisible(true);
          }}
        >
          Add New Drill
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            fetchAvailableDrills();
            setIsExistingDrillVisible(true);
          }}
        >
          Add Existing Drill
        </Button>
      </div>

      <DrillTable
        sessionDrills={sessionDrills}
        drillFilesCount={drillFilesCount}
        handleViewFiles={handleViewFiles}
        handleEditDrill={handleEditDrill}
        handleDeleteDrill={handleDeleteDrill}
      />

      {/* File View Modal */}
      <FileViewModal
        isVisible={isFileModalVisible}
        onClose={() => setIsFileModalVisible(false)}
        drillDetails={selectedDrillDetails}
        drillFiles={drillFiles}
        handlePreview={handlePreview}
        handleDeleteFile={(file) => handleDeleteFile(file, selectedDrill.drill_id)}
      />
      {/* Custom Drill Modal */}
      <CustomDrillModal
        isVisible={isCustomDrillVisible}
        onClose={handleCloseCustomDrillModal}
        form={form}
        handleSaveDrill={handleSaveDrill}
        editingDrill={editingDrill}
        drillFiles={drillFiles}
        fileList={fileList}
        handleUploadChange={handleUploadChange}
        handleRemoveFile={handleRemoveFile}
        handleDeleteFile={handleDeleteFile}
        addToPlan={addToPlan}
        setAddToPlan={setAddToPlan}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />

      {/* Existing Drill Modal */}
      <ExistingDrillModal
        isVisible={isExistingDrillVisible}
        onClose={() => {
          setIsExistingDrillVisible(false);
          setAvailableDrills([]);
        }}
        availableDrills={availableDrills}
        handleAddExistingDrills={handleAddExistingDrills}
      />
      {/* Preview Modal */}
      <PreviewModal
        visible={isPreviewVisible}
        onClose={() => setIsPreviewVisible(false)}
        fileUrl={previewFile.fileUrl}
        fileName={previewFile.fileName}
      />
    </div>
  );
};

export default Plan;