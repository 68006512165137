import React from 'react';
import { Modal, Descriptions, Divider,message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FilesTable from './FilesTable';

const FileViewModal = ({
  isVisible,
  onClose,
  drillDetails,
  drillFiles,
  handlePreview,
  handleDeleteFile
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={800}
      zIndex={10}
      bodyStyle={{ padding: '20px' }}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Drill Name">{drillDetails.name}</Descriptions.Item>
        <Descriptions.Item label="Description">{drillDetails.description}</Descriptions.Item>
        <Descriptions.Item label="Duration">
          {drillDetails.duration} min
        </Descriptions.Item>
      </Descriptions>
      
      <Divider />

      <FilesTable
        files={drillFiles}
        onPreview={(record) => handlePreview(record.id, record.name)}
        onDelete={(record) => {
          Modal.confirm({
            title: "Are you sure you want to delete this file?",
            icon: <ExclamationCircleOutlined />,
            content: `File: ${record.name}`,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
              try {
                await handleDeleteFile(record, record.id);
                message.success("File deleted successfully");
              } catch (error) {
                console.error("Failed to delete file:", error);
                message.error("Failed to delete file");
              }
            },
          });
        }}
      />
    </Modal>
  );
};

export default FileViewModal;