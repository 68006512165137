import { useEffect, useState } from "react";
import supabase from "../supabaseClient";

const checkWhitelist = async (email) => {
  try {
    const { data, error } = await supabase
      .from("whitelist")
      .select("email, is_invited")
      .eq("email", email)
      .single();

    if (error) {
      console.error("Whitelist check error:", error);
      return false;
    }

    return data?.is_invited || false;
  } catch (err) {
    console.error("Unexpected error checking whitelist:", err);
    return false;
  }
};

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session?.user) {
          setUser(session.user);
          fetchUserProfile(session.user.id);
        } else {
          setUser(null);
        }
        setLoading(false);
      }
    );

    const getUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
        fetchUserProfile(user.id);
      }
      setLoading(false);
    };
    getUser();

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const fetchUserProfile = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("first_name, last_name")
        .eq("id", userId)
        .single();

      if (error) {
        console.error("Error fetching user profile:", error);
      } else {
        setUser((prevUser) => ({ ...prevUser, ...data }));
      }
    } catch (error) {
      console.error("Unexpected error fetching user profile:", error);
    }
  };

  return { user, loading };
};

export const signUp = async (email, password, firstName, lastName) => {

  // Check if email is whitelisted
  const isWhitelisted = await checkWhitelist(email);
  if (!isWhitelisted) {
    return { error: { message: "Account creation is restricted. Please wait for an invitation to join." } };
  }

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  if (!passwordRegex.test(password)) {
    return { error: { message: "Password does not meet requirements." } };
  }
  try {
    const { data, error } = await supabase.auth.signUp({ email, password });
    if (error) {
      console.error("Signup error:", error.message);
      throw error;
    }

    // If signup is successful, insert user profile data
    const user = data.user;
    console.log("User data after signup:", user);
    if (user) {
      const { error: profileError } = await supabase
        .from("profiles")
        .insert([{ id: user.id, first_name: firstName, last_name: lastName }]);

      if (profileError) {
        console.error("Profile insertion error:", profileError); // Log the full profileError object
        return { error: profileError };
      }
    }
    return { data };
  } catch (err) {
    console.error("Unexpected error:", err); // Log the full error object
    return {
      error: { message: "An unexpected error occurred. Please try again." },
    };
  }
};

export const login = async (email, password) => {
  // Check if email is whitelisted
  const isWhitelisted = await checkWhitelist(email);
  if (!isWhitelisted) {
    return { error: { message: "Your account is not authorized to log in. Please contact support." } };
  }

  // Proceed with login if whitelisted
  try {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      console.error("Login error:", error.message);
      return { error };
    }
    return { data };
  } catch (err) {
    console.error("Unexpected login error:", err);
    return { error: { message: "An unexpected error occurred. Please try again." } };
  }
};

export const logout = async () => {
  return await supabase.auth.signOut();
};
