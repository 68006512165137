// src/components/EventForm.jsx
import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, TimePicker, Select, Button, message } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getTeams, getSessions, createEvent, updateEvent } from '../api/events';
import { getSessions as fetchSessions } from "../../Sessions/api/sessions"; // Import the API function to fetch sessions

dayjs.extend(utc);
const { Option } = Select;

const EventForm = ({ initialValues, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [teams, setTeams] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [eventType, setEventType] = useState(initialValues?.entity_type || ''); 
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await getTeams();
        setTeams(response.data);
      } catch (error) {
        console.error("Error fetching teams:", error);
        message.error("Failed to fetch teams.");
      }
    };
    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchSessionsData = async () => {
      try {
        const response = await fetchSessions();
        setSessions(response.data);
      } catch (error) {
        console.error("Error fetching sessions:", error);
        message.error("Failed to fetch sessions.");
      }
    };

    if (eventType === "Session") {
      fetchSessionsData();
    } else {
      setSessions([]); // Clear sessions if event type is not "Session"
    }
  }, [eventType]);

  useEffect(() => {
    if (initialValues) {
      const formattedInitialValues = {
        ...initialValues,
        date: initialValues.date ? dayjs.utc(initialValues.date) : undefined,
        start_time: initialValues.start_time ? dayjs(initialValues.start_time, "HH:mm:ss") : undefined,
        end_time: initialValues.end_time ? dayjs(initialValues.end_time, "HH:mm") : undefined,
        session_id: initialValues.entity_type === "Session" ? initialValues.entity_id : undefined, // Map entity_id to session_id
      };
      form.setFieldsValue(formattedInitialValues);
      setEventType(initialValues.entity_type);
    } else {
      form.resetFields();
      setEventType('');
    }
  }, [initialValues, form]);

  const onFinish = async (values) => {
    // Determine the entity_id based on the event type
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true); // Set submitting state to true
    let entity_id = null;
    if (eventType === "Session" && values.session_id) {
      entity_id = values.session_id;
    }

    // Prepare the formatted values to be sent to the backend
    const formattedValues = {
      ...values,
      entity_id, // Set entity_id based on the selected session
      entity_type: eventType, // Store eventType as entity_type
      date: values.date ? values.date.format("YYYY-MM-DD") : null,
      start_time: values.start_time ? values.start_time.format("HH:mm") : null,
      end_time: values.end_time ? values.end_time.format("HH:mm") : null,
    };

    // Remove session_id from the payload, as we store this in entity_id instead
    delete formattedValues.session_id;

    console.log("[onFinish] Formatted values:", formattedValues);

    try {
      if (initialValues?.event_id) {
        // Update existing event
        const response = await updateEvent(initialValues.event_id, formattedValues);
        onSave(response.data);
      } else {
        // Create new event
        const response = await createEvent(formattedValues);
        onSave(response.data);
      }

      form.resetFields();
      setEventType('');
    } catch (error) {
      console.error("Error submitting event form:", error);
      message.error("Failed to submit event.");
    }finally {
      setIsSubmitting(false); // Reset submitting state after completion
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="eventForm"
      onFinish={onFinish}
      autoComplete="off"
    >
      {/* Event Name Field */}
      <Form.Item
        name="event_name"
        label="Event Name"
        rules={[{ required: true, message: "Please enter the event name" }]}
      >
        <Input placeholder="Enter Event Name" />
      </Form.Item>

      {/* Event Type Field */}
      <Form.Item
        name="entity_type"
        label="Event Type"
        rules={[{ required: true, message: "Please select the event type" }]}
      >
        <Select
          placeholder="Select Event Type"
          onChange={(value) => setEventType(value)} // Use the selected value directly without converting to lowercase
        >
          <Option value="Session">Session</Option>
          <Option value="Match">Match</Option>
          <Option value="Meeting">Meeting</Option>
        </Select>
      </Form.Item>

      {/* Session Dropdown (only visible if event type is "Session") */}
      {eventType === "Session" && (
        <Form.Item name="session_id" label="Select Session">
          <Select placeholder="Select Session" allowClear>
            {sessions.map((session) => (
              <Option key={session.session_id} value={session.session_id}>
                {session.session_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="team_id" label="Team" rules={[{ required: true, message: "Please select a team" }]}>
        <Select placeholder="Select Team">
          {teams.map((team) => (
            <Option key={team.team_id} value={team.team_id}>
              {team.team_name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="date" label="Date" rules={[{ required: true, message: "Please select the date" }]}>
        <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item name="start_time" label="Start Time">
        <TimePicker style={{ width: "100%" }} format="HH:mm" />
      </Form.Item>

      <Form.Item
        name="end_time"
        label="End Time"
        dependencies={["start_time"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const start_time = getFieldValue("start_time");
              if (!value || !start_time || value.isAfter(start_time)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("End time must be after start time"));
            },
          }),
        ]}
      >
        <TimePicker style={{ width: "100%" }} format="HH:mm" />
      </Form.Item>

      <Form.Item name="location" label="Location">
        <Input placeholder="Enter Location (Optional)" />
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>Cancel</Button>
        <Button type="primary" htmlType="submit" disabled={isSubmitting} >
          {initialValues?.event_id ? "Update" : "Add"}
        </Button>
        
      </Form.Item>
    </Form>
  );
};

export default EventForm;
