import React from "react";
import { Table, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const { Link } = Typography;

const DrillTable = ({ sessionDrills, drillFilesCount, handleViewFiles, handleEditDrill, handleDeleteDrill }) => {
  const columns = [
    {
      title: "Drill Name",
      dataIndex: "drill_name",
      key: "name",
      render: (text, record) => (
        <Link onClick={() => handleViewFiles(record)}>{text}</Link>
      ),
    },
    { title: "Duration (min)", dataIndex: "duration", key: "duration" },
    {
      title: "Files",
      key: "files",
      render: (_, record) => drillFilesCount[record.drill_id] || 0,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleEditDrill(record)}>
                Edit
              </Menu.Item>
              <Menu.Item
                onClick={() => handleDeleteDrill(record.drill_id)}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sessionDrills}
      rowKey="drill_id"
    />
  );
};

export default DrillTable;