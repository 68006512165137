// src/components/PlayerProfileModal.jsx

import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Descriptions, Spin, message, Progress } from 'antd';
import { fetchPlayerAttendanceStats } from '../api/teams';
import dayjs from 'dayjs';

const { TabPane } = Tabs;

const PlayerProfileModal = ({ visible, onClose, player }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [attendanceStats, setAttendanceStats] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible && player) {
            // Fetch attendance stats
            const fetchStats = async () => {
                setLoading(true);
                try {
                    const response = await fetchPlayerAttendanceStats(player.key); // Assuming 'key' is player_id
                    setAttendanceStats(response.data);
                } catch (error) {
                    console.error('Error fetching attendance stats:', error);
                    message.error('Failed to fetch attendance stats.');
                } finally {
                    setLoading(false);
                }
            };

            fetchStats();
        }
    }, [visible, player]);

    const renderAttendanceItem = (label, attended, total) => {
        const percentage = total > 0 ? (attended / total) * 100 : 0;
        return (
            <Descriptions.Item label={label}>
                <Progress
                    percent={percentage.toFixed(2)}
                    format={() => `${attended} / ${total}`}
                    status={percentage >= 75 ? 'success' : percentage >= 50 ? 'active' : 'exception'}
                    showInfo
                />
            </Descriptions.Item>
        );
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={null}
            title={`${player?.name}`}
            width={700}
        >
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tab="Overall Stats" key="1">
                    {/* Overall Stats content can be added here */}
                    <p>Overall stats will be available soon.</p>
                </TabPane>
                <TabPane tab="Attendance" key="2">
                    {loading ? (
                        <Spin size="large" style={{ display: 'block', margin: 'auto' }} />
                    ) : attendanceStats ? (
                        <Descriptions bordered column={1}>
                            {renderAttendanceItem(
                                "Sessions Attended",
                                attendanceStats.sessions.attended,
                                attendanceStats.sessions.total
                            )}
                            {renderAttendanceItem(
                                "Matches Attended",
                                attendanceStats.matches.attended,
                                attendanceStats.matches.total
                            )}
                            {renderAttendanceItem(
                                "Meetings Attended",
                                attendanceStats.meetings.attended,
                                attendanceStats.meetings.total
                            )}
                        </Descriptions>
                    ) : (
                        <p>No attendance data available.</p>
                    )}
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default PlayerProfileModal;
