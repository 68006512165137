import React, { useEffect, useState } from "react";
import { Layout, message } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";
import supabase from "./supabaseClient";
import SideNav from "./components/SideNav";
import Home from "./pages/Home/HomePage";
import Teams from "./pages/Teams/TeamsPage";
import TeamDetails from "./pages/Teams/components/Team";
import Sessions from "./pages/Sessions/SessionsPage";
import SessionDetails from "./pages/Sessions/components/Session";
import CalendarPage from "./pages/Calendar/CalendarPage";
import CatalogPage from "./pages/Catalog/CatalogPage";
import AttendancePage from "./pages/Attendance/AttendancePage";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import PrivateRoute from "./components/PrivateRoute";
import LoadingSpinner from "./components/LoadingSpinner";
import SettingsPage from "./pages/Settings/SettingsPage";

const { Content } = Layout;

const App = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Check if a session is active when the app loads
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      setLoading(false); // Session check complete
    };
    getSession();

    // Listen for authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setSession(session);
      }
    );

    // Clean up the auth listener on component unmount
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        supabase.auth.signOut();
        setSession(null);
        message.warning("You have been signed out due to inactivity.");
      }, 3600000); // 1 hour in milliseconds
    };

    const events = ["mousemove", "keydown", "click"];

    events.forEach((event) => window.addEventListener(event, resetTimeout));

    resetTimeout(); // Initialize the timeout

    return () => {
      if (timeout) clearTimeout(timeout);
      events.forEach((event) => window.removeEventListener(event, resetTimeout));
    };
  }, [session]);

  // Sample teams and events state with dummy data
  const [teams, setTeams] = useState([]);

  const [events, setEvents] = useState([]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {session && <SideNav />} {/* Show SideNav only if authenticated */}
      <Layout>
        <Content style={{ padding: "24px", background: "#fff" }}>
          <Routes>
            {/* Auth routes */}
            <Route
              path="/login"
              element={!session ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/signup"
              element={!session ? <Signup /> : <Navigate to="/" />}
            />

            {/* Protected routes */}
            <Route
              path="/"
              element={
                <PrivateRoute session={session}>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/teams"
              element={
                <PrivateRoute session={session}>
                  <Teams teams={teams} setTeams={setTeams} />
                </PrivateRoute>
              }
            />
            <Route
              path="/teams/:teamId"
              element={
                <PrivateRoute session={session}>
                  <TeamDetails teams={teams} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sessions"
              element={
                <PrivateRoute session={session}>
                  <Sessions
                    events={events}
                    setEvents={setEvents}
                    teams={teams}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/sessions/:sessionId"
              element={
                <PrivateRoute session={session}>
                  <SessionDetails
                    events={events}
                    teams={teams}
                    setEvents={setEvents}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <PrivateRoute session={session}>
                  <CalendarPage events={events} setEvents={setEvents} />
                </PrivateRoute>
              }
            />
            <Route
              path="/catalog"
              element={
                <PrivateRoute session={session}>
                  <CatalogPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/attendance"
              element={
                <PrivateRoute session={session}>
                  <AttendancePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute session={session}>
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            {/* Redirect any unknown routes to home or login based on session */}
            <Route
              path="*"
              element={session ? <Navigate to="/" /> : <Navigate to="/login" />}
            />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;