import React from 'react';
import { Modal, Form, Input, InputNumber, Checkbox, Table, Dropdown, Menu, Button, Upload } from 'antd';
import { MoreOutlined, UploadOutlined } from '@ant-design/icons';

const CustomDrillModal = ({
  isVisible,
  onClose,
  form,
  handleSaveDrill,
  editingDrill,
  drillFiles,
  fileList,
  handleUploadChange,
  handleRemoveFile,
  handleDeleteFile,
  addToPlan,
  setAddToPlan,
  isSubmitting,
  setIsSubmitting
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleSaveDrill}>
        <Form.Item
          name="drill_name"
          label="Drill Name"
          rules={[{ required: true, message: "Please enter the drill name" }]}
        >
          <Input placeholder="Enter Drill Name" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={3} placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          name="duration"
          label="Duration (min)"
          rules={[{ required: true, message: "Please enter the duration" }]}
        >
          <InputNumber min={1} style={{ width: "100%" }} placeholder="Enter Duration" />
        </Form.Item>

        {!editingDrill && (
          <Form.Item>
            <Checkbox
              checked={addToPlan}
              onChange={(e) => setAddToPlan(e.target.checked)}
            >
              Add Drill To This Session
            </Checkbox>
          </Form.Item>
        )}

        {editingDrill && drillFiles.length > 0 && (
          <Form.Item label="Existing Files">
            <Table
              dataSource={drillFiles}
              columns={[
                {
                  title: 'File Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: (_, record) => (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleDeleteFile(record, editingDrill.drill_id)}>
                            Delete
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <MoreOutlined style={{ cursor: "pointer" }} />
                    </Dropdown>
                  ),
                },
              ]}
              rowKey="uid"
              pagination={false}
            />
          </Form.Item>
        )}

        <Form.Item label="Upload New Files">
          <Upload
            fileList={fileList}
            onChange={handleUploadChange}
            onRemove={handleRemoveFile}
            beforeUpload={() => false} // Prevent automatic upload
            multiple
          >
            <Button icon={<UploadOutlined />}>Select Files</Button>
          </Upload>
        </Form.Item>

        {fileList.length > 0 && (
          <Table
            dataSource={fileList}
            columns={[
              {
                title: 'File Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => record.name || record.originFileObj.name,
              },
              {
                title: 'Actions',
                key: 'actions',
                render: (_, record) => (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleRemoveFile(record)}>
                          Remove
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <MoreOutlined style={{ cursor: "pointer" }} />
                  </Dropdown>
                ),
              },
            ]}
            rowKey="uid"
            pagination={false}
          />
        )}

        <Form.Item style={{ textAlign: "right" }}>
          <Button onClick={onClose}style={{ marginRight: 8}}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {editingDrill ? "Update" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomDrillModal;