import React from 'react';
import { message, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuthForm from './components/AuthForm';
import { signUp } from '../../hooks/useAuth';
import logo from "../../assets/logo.jpg"; // Adjust the path as necessary

const Signup = () => {
  const navigate = useNavigate();

  const handleSignup = async ({ firstName, lastName, email, password }) => {
    const { error } = await signUp(email, password, firstName, lastName);
    if (error) {
      message.error(error.message);
    } else {
      message.success('Signup successful');
      navigate('/'); // Redirect to home after successful signup
    }
  };

  const goToLogin = () => {
    navigate('/login'); // Navigate to login page
  };

  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      {/* Logo */}
      <div style={{ marginBottom: '20px' }}>
        <img src={logo} alt="Logo" style={{ width: '150px' }} />
      </div>
      <h2>Sign Up</h2>
      <AuthForm onSubmit={handleSignup} buttonText="Sign Up" isSignup={true} />
      <Button type="link" onClick={goToLogin} style={{ marginTop: '1rem' }}>
        Already have an account? Log in
      </Button>
    </div>
  );
};

export default Signup;