import axios from 'axios';
import supabase from '../supabaseClient';
import { BASE_URL } from './config';

const api = axios.create({
  baseURL: BASE_URL
});

// Add JWT token to each request's Authorization header
api.interceptors.request.use(async (config) => {
  const { data: { session } } = await supabase.auth.getSession();
  if (session) {
    config.headers.Authorization = `Bearer ${session.access_token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

console.log(api.defaults.baseURL);
export default api;