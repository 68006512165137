// frontend/src/pages/Settings/api/settings.js

import api from '../../../config/api';
import axios from 'axios';

/**
 * Get current authenticated user's details.
 * @param {string} userId - The ID of the user.
 * @returns {Promise} - Axios response promise.
 */
export const getUserDetails = (userId) => api.get(`/api/users/${userId}`);

/**
 * Update current authenticated user's details.
 * @param {string} userId - The ID of the user.
 * @param {Object} userData - The data to update (e.g., first_name, last_name).
 * @returns {Promise} - Axios response promise.
 */
export const updateUserDetails = (userId, userData) => api.put(`/api/users/${userId}`, userData);

/**
 * Delete current authenticated user's account.
 * @param {string} userId - The ID of the user.
 * @param {string} accessToken - The access token of the user.
 * @returns {Promise} - Axios response promise.
 */
export const deleteUserAccount = (userId, accessToken) => 
    api.delete(`/api/users/${userId}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });