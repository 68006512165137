import React from "react";
import { Modal } from "antd";

const PreviewModal = ({ visible, onClose, fileUrl, fileName }) => {
  const renderContent = () => {
    if (!fileName) {
      return <div>No file selected</div>;
    }

    const fileExtension = fileName.split('.').pop().toLowerCase();
    console.log('File extension:', fileExtension);

    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'].includes(fileExtension)) {
      console.log('Rendering image');
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${fileUrl})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      );
    }

    if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'].includes(fileExtension)) {
      console.log('Rendering video');
      return (
        <video controls style={{ width: "100%", height: "100%" }}>
          <source src={fileUrl} type={`video/${fileExtension}`} />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (['mp3', 'wav', 'aac', 'flac', 'ogg'].includes(fileExtension)) {
      console.log('Rendering audio');
      return (
        <audio controls style={{ width: "100%" }}>
          <source src={fileUrl} type={`audio/${fileExtension}`} />
          Your browser does not support the audio element.
        </audio>
      );
    }

    if (['pdf', 'txt', 'rtf', 'md'].includes(fileExtension)) {
      console.log('Rendering document');
      return (
        <iframe
          src={fileUrl}
          title={fileName}
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      );
    }

    if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension)) {
      console.log('Rendering office document');
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}
          title={fileName}
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      );
    }

    console.log('Unsupported file type');
    return <div>Unsupported file type</div>;
  };

  return (
    <Modal
      visible={visible}
      title={`${fileName || 'No file'}`}
      footer={null}
      onCancel={onClose}
      width="80vw"
      bodyStyle={{ height: "80vh", overflow: "hidden" }}
      style={{ zIndex: 1050, top: 45 }}
    >
      {renderContent()}
    </Modal>
  );
};

export default PreviewModal;
