import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Dropdown, Menu, Select, message } from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import useNotes from "./hooks/useNotes";
import useUsers from "./hooks/useUsers";

const { Option } = Select;

const NotesComponent = ({ entityId, entityType }) => {
  const { notes, loading, handleCreateNote, handleUpdateNote, handleDeleteNote } = useNotes(entityId, entityType);
  const { users, loading: usersLoading } = useUsers();

  const [usersLookup, setUsersLookup] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form] = Form.useForm();

  // Create a user lookup table based on the fetched users
  useEffect(() => {
    const lookup = {};
    users.forEach((user) => {
      lookup[user.id] = `${user.first_name} ${user.last_name}`;
    });
    setUsersLookup(lookup);
  }, [users]);

  // Debugging: Log current notes to verify updates
  useEffect(() => {
    console.log("Current notes:", notes);
  }, [notes]);

  // Function to save a new or edited note
  const handleSaveNote = async (noteData) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state
  
    try {
      if (editingNote) {
        const updatedNote = await handleUpdateNote(editingNote.note_id, noteData); // Use note_id for editing
        console.log("Updated Note Response:", updatedNote);
        message.success("Note updated successfully!");
      } else {
        console.log('Creating new note:', noteData);
        const newNote = await handleCreateNote(noteData);
        console.log("New Note Response:", newNote);
        message.success("Note added successfully!");
      }
      setIsModalVisible(false);
      setEditingNote(null);
      form.resetFields();
    } catch (error) {
      console.error("Failed to save note. Error details:", error);
      message.error("Failed to save note.");
    }finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  // Edit a specific note
  const handleEdit = (record) => {
    console.log("[handleEdit] Editing note:", record);
    setEditingNote(record); // Store the whole record, including note_id
    form.setFieldsValue(record); // Populate form fields with record data
    setIsModalVisible(true);
  };

  // Delete a specific note
  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this note?",
      content: `Note: ${record.note_name || record.note_text}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
      onOk: async () => {
        try {
          await handleDeleteNote(record.note_id); // Use note_id here
          message.success("Note deleted successfully!");
        } catch (error) {
          console.error("Failed to delete note:", error);
          message.error("Failed to delete note.");
        }
      },
    });
  };

  // View a specific note
  const handleView = (record) => {
    Modal.info({
      content: (
        <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <p><strong>Title:</strong> {record.note_name}</p>
          <p> {record.note_text}</p>
        </div>
      ),
      okText: "Close",
      width: "60%",
      centered: true,
    });
  };

  // Define table columns
  const columns = [
    {
      title: "Title",
      dataIndex: "note_name",
      key: "note_name",
      render: (noteName, record) => (
        <a onClick={() => handleView(record)}>{noteName}</a>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date_created",
      render: (created_at) => {
        const date = new Date(created_at);
        return date.toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        });
      },
    },
    {
      title: "Uploaded At",
      dataIndex: "created_at",
      key: "time_created",
      render: (created_at) => {
        const date = new Date(created_at);
        return date.toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item onClick={() => handleView(record)}>View</Menu.Item>
            <Menu.Item onClick={() => handleEdit(record)}>Edit</Menu.Item>
            <Menu.Item onClick={() => handleDelete(record)}>Delete</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setEditingNote(null); // Reset editing note
          form.resetFields(); // Reset form fields
          setIsModalVisible(true); // Show modal
        }}
        style={{ marginBottom: 16 }}
      >
        Add New Note
      </Button>

      <Table
        columns={columns}
        dataSource={notes} // Use hook's notes directly
        rowKey="note_id" // Changed from "id" to "note_id" to reflect the new field name
        loading={loading || usersLoading}
        pagination={{ pageSize: 5 }}
      />

      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
        centered
        
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) =>
            handleSaveNote({
              ...values
            })
          }
        >
          <Form.Item
            name="note_name"
            label="Title"
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input placeholder="Enter Title " />
          </Form.Item>

          <Form.Item
            name="note_text"
            label="Note"
            rules={[{ required: true, message: "Please enter the note" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter Note" />
          </Form.Item>

          <Form.Item style={{ textAlign: "right" }}>
          <Button onClick={() => setIsModalVisible(false)} style={{ marginRight: 8 }}>Cancel</Button>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              {editingNote ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default NotesComponent;

