import React, { useEffect, useState } from "react";
import { Card, List, Empty, Tag } from "antd";
import { MedicineBoxOutlined, SmileOutlined } from "@ant-design/icons";
import { getCurrentInjuries, getPlayerDetails, getTeamDetails } from "../api/home";

const CurrentInjuries = ({ }) => {
  const [injuries, setInjuries] = useState([]);

  useEffect(() => {
    getCurrentInjuries()
      .then(response => {
        const injuriesData = response.data;
        const playerPromises = injuriesData.map(injury =>
          getPlayerDetails(injury.player_id)
        );
        const teamPromises = injuriesData.map(injury =>
          getTeamDetails(injury.team_id)
        );

        Promise.all([Promise.all(playerPromises), Promise.all(teamPromises)])
          .then(([players, teams]) => {
            const injuriesWithDetails = injuriesData.map((injury, index) => ({
              ...injury,
              player_name: players[index].data.player_name,
              player_position: players[index].data.position,
              team_name: teams[index].data.team_name,
              team_color: teams[index].data.team_color
            }));
            setInjuries(injuriesWithDetails);
          })
          .catch(error => {
            console.error("Error fetching player or team details:", error);
          });
      })
      .catch(error => {
        console.error("There was an error fetching the injuries!", error);
      });
  }, []);

  return (
    <Card
      title={
        <span>
          <MedicineBoxOutlined style={{ marginRight: "8px" }} />
          Current Injuries
        </span>
      }
      style={{ marginBottom: "16px" }}
      bordered={false}
    >
      {injuries.length === 0 ? (
        <Empty
          description="Fully Fit Team"
          image={<SmileOutlined style={{ fontSize: 48, color: 'gray', paddingTop: 10 }} />}
          style={{ marginTop: '-16px' }}
          imageStyle={{ height: 60 }}
        />
      ) : (
        <div
        style={{ 
          height: "30vh", 
          overflowY: "auto" 
          }} 
        >
          <List
            dataSource={injuries}
            renderItem={(item) => (
              <List.Item>
                {item.player_name} - {item.player_position} - {item.injury_type}
                <Tag color={item.team_color.toLowerCase()} style={{ marginLeft: "8px" }}>
                  {item.team_name}
                </Tag>
              </List.Item>
            )}
          />
        </div>
      )}
    </Card>
  );
};

export default CurrentInjuries;
