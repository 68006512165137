import React from 'react';
import { Table, Dropdown, Menu, message } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const FilesTable = ({ files, onPreview, onDelete }) => {
  const handleDownload = async (record) => {
    try {
      message.info(`Downloading ${record.name}`);
      
      // Fetch the file
      const response = await fetch(record.file_url);
      const blob = await response.blob();
      
      // Create a blob URL
      const blobUrl = window.URL.createObjectURL(blob);
      
      // Create an anchor element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = record.name;
      
      // Append to document, click, and remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the blob URL
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Download failed:", error);
      message.error(`Failed to download ${record.name}`);
    }
  };

  const actionMenu = (record) => (
    <Menu>
      <Menu.Item key="preview" onClick={() => onPreview(record)}>
        Preview
      </Menu.Item>
      <Menu.Item key="download" onClick={() => handleDownload(record)}>
        Download
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => onDelete(record)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a href="#" onClick={(e) => { e.preventDefault(); onPreview(record); }}>
          {text}
        </a>
      ),
    },
    {
      title: "Uploaded At",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Table columns={columns} dataSource={files} rowKey="key" />
  );
};

export default FilesTable;