// src/api/teams.js

import api from '../../../config/api';

// Team-related API calls

// Get all teams
export const getTeams = () => api.get('/api/teams');

// Get a single team by ID
export const getTeamById = (teamId) => api.get(`/api/teams/${teamId}`);

// Create a new team
export const createTeam = (teamData) => api.post('/api/teams', teamData);

// Update a team by ID
export const updateTeam = (teamId, teamData) => api.put(`/api/teams/${teamId}`, teamData);

// Delete a team by ID
export const deleteTeam = (teamId) => api.delete(`/api/teams/${teamId}`);

// Player-related API calls

// Get all players for a specific team
export const getPlayersByTeamId = (teamId) => api.get(`/api/teams/${teamId}/players`);

// Create a new player
export const createPlayer = (playerData) => api.post('/api/players', playerData);

// Update an existing player by ID
export const updatePlayer = (playerId, playerData) => api.put(`/api/players/${playerId}`, playerData);

// Delete a player by ID
export const deletePlayer = (playerId) => api.delete(`/api/players/${playerId}`);

// Injury-related API calls

// Get injuries for a specific team
export const getInjuriesByTeamId = (teamId) => api.get(`/api/injuries/team/${teamId}`);

// Get a single injury by ID
export const getInjuryById = (injuryId) => api.get(`/api/injuries/${injuryId}`);

// Create a new injury
export const createInjury = (injuryData) => api.post('/api/injuries', injuryData);

// Update an injury by ID
export const updateInjury = (injuryId, injuryData) => api.put(`/api/injuries/${injuryId}`, injuryData);

// Delete an injury by ID
export const deleteInjury = (injuryId) => api.delete(`/api/injuries/${injuryId}`);

// User-related API calls

// Fetch all users
export const getAllUsers = () => api.get('/api/users');

// Fetch user details by ID
export const getUserById = (userId) => api.get(`/api/users/${userId}`);

export const fetchPlayerAttendanceStats = (playerId) => 
    api.get(`/api/attendance/player/${playerId}/stats`);
