// src/components/Notes/api/users.js
import api from '../../../config/api';

// Get all users
export const getAllUsers = () => {
    return api.get('/api/users');
  };
  
  // Create a new user
  export const createUser = (userData) => {
    return api.post('/api/users', userData);
  };
  
  // Get a specific user by ID
  export const getUserById = (id) => {
    return api.get(`/api/users/${id}`);
  };
  
  // Update a specific user by ID
  export const updateUser = (id, userData) => {
    return api.put(`/api/users/${id}`, userData);
  };
  
  // Delete a specific user by ID
  export const deleteUser = (id) => {
    return api.delete(`/api/users/${id}`);
  };
  