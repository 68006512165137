// src/components/AttendanceModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, Row, Col, Spin, message } from 'antd';
import PropTypes from 'prop-types';

const AttendanceModal = ({ visible, onClose, players = [], onSubmit, initialAttendance }) => {
    const [attendance, setAttendance] = useState({});
    const [loading, setLoading] = useState(true); // Track loading state

    useEffect(() => {
        console.log("Players data:", players); // Debugging: check players data on each render
        if (Array.isArray(players) && players.length > 0) {
            const initialAttendanceState = {};
            initialAttendance.forEach(player => {
                initialAttendanceState[player.player_id] = player.attended; // Set initial attendance status
            });
            setAttendance(initialAttendanceState);
        }
        setLoading(false); // Stop loading once data is set
    }, [initialAttendance, players, visible]);

    const handleToggle = (playerId) => {
        setAttendance((prev) => ({
            ...prev,
            [playerId]: !prev[playerId], // Toggle attendance status
        }));
    };

    const handleSubmit = () => {
        const attendanceData = Object.entries(attendance).map(([playerId, isPresent]) => ({
            playerId,
            attended: isPresent,
        }));

        onSubmit(attendanceData); // Submit the attendance data
        onClose(); // Close the modal after submitting
    };

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Update
                </Button>,
            ]}
            width={450}
            closable={true}
            bodyStyle={{ padding: '20px', maxHeight: '70vh', overflowY: 'auto',margin:'20px' }} // Set maxHeight and enable scroll
        >
            {loading ? (
                <div style={{ textAlign: 'center', padding: '50px 0' }}>
                    <Spin size="large" />
                </div>
            ) : (!Array.isArray(players) || players.length === 0) ? (
                <p>No players found for this team.</p>
            ) : (
                <div>
                    {players.map((player) => (
                        <Row
                            key={player.player_id}
                            style={{
                                marginBottom: 16,
                                padding: 10,
                                border: '1px solid #f0f0f0',
                                borderRadius: 4,
                                alignItems: 'center',
                            }}
                        >
                            <Col span={24}>
                                <Checkbox
                                    checked={attendance[player.player_id] || false} // Reflect current attendance status
                                    onChange={() => handleToggle(player.player_id)}
                                >
                                    <span style={{ fontWeight: 'bold' }}>{player.player_name}</span>
                                    <span style={{ marginLeft: '10px', color: 'gray' }}>
                                        (#{player.jersey_no}, {player.position})
                                    </span>
                                </Checkbox>
                            </Col>
                        </Row>
                    ))}
                </div>
            )}
        </Modal>
    );
};

AttendanceModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    players: PropTypes.arrayOf(
        PropTypes.shape({
            player_id: PropTypes.number.isRequired,
            player_name: PropTypes.string.isRequired,
            jersey_no: PropTypes.number.isRequired,
            position: PropTypes.string.isRequired,
        })
    ),
    onSubmit: PropTypes.func.isRequired,
    initialAttendance: PropTypes.arrayOf(
        PropTypes.shape({
            player_id: PropTypes.number.isRequired,
            attended: PropTypes.bool.isRequired,
        })
    ),
};

export default AttendanceModal;
