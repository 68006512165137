// src/components/Notes/api/notes.js
import api from '../../../config/api';

// Create a new note
export const createNote = (noteData) => api.post('/api/notes', noteData);

// Get all notes for a specific entity
export const getNotesByEntity = (entityId, entityType) => 
  api.get(`/api/notes/${entityType}/${entityId}`);

// Update an existing note by noteId
export const updateNote = (noteId, noteData) => api.put(`/api/notes/${noteId}`, noteData);

// Delete a note by noteId
export const deleteNote = (noteId) => api.delete(`/api/notes/${noteId}`);
