// src/hooks/useNotes.js

import { useState, useEffect } from "react";
import { message } from "antd";
import { createNote, getNotesByEntity, updateNote, deleteNote } from "../api/notes";

const useNotes = (entityId, entityType) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotes = async () => {
      console.log(`[fetchNotes] Fetching notes for entityType: ${entityType} with entityId: ${entityId}`);
      try {
        const response = await getNotesByEntity(entityId, entityType);
        console.log("[fetchNotes] Response data:", response.data);
        setNotes(response.data);
        setLoading(false);
      } catch (error) {
        console.error(`[fetchNotes] Error fetching notes for entityType: ${entityType} with entityId: ${entityId}`, error);
        message.error("Failed to fetch notes.");
        setLoading(false);
      }
    };

    if (entityId) {
      fetchNotes();
    }
  }, [entityId, entityType]);

  const handleCreateNote = async (noteData) => {
    try {
      const response = await createNote({
        ...noteData,
        entity_id: entityId, // Use entity_id to associate with the entity
        entity_type: entityType, // Include entity_type for more general use
      });
      const newNote = Array.isArray(response.data) ? response.data[0] : response.data;
      setNotes((prevNotes) => [...prevNotes, newNote]);
      return newNote; // Return the new note for potential use
    } catch (error) {
      console.error("[handleCreateNote] Error creating note:", error);
      message.error("Failed to create note.");
      throw error; // Rethrow to handle in component
    }
  };

  const handleUpdateNote = async (noteId, noteData) => {
    try {
      const response = await updateNote(noteId, noteData);
      const updatedNote = Array.isArray(response.data) ? response.data[0] : response.data;
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.note_id === noteId ? { ...note, ...updatedNote } : note
        )
      );
      return updatedNote; // Return updated note
    } catch (error) {
      console.error("[handleUpdateNote] Error updating note:", error);
      message.error("Failed to update note.");
      throw error; // Rethrow to handle in component
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await deleteNote(noteId);
      console.log("[handleDeleteNote] Deleted note ID:", noteId);
      setNotes((prevNotes) => prevNotes.filter((note) => note.note_id !== noteId));
    } catch (error) {
      console.error("[handleDeleteNote] Error deleting note:", error);
      message.error("Failed to delete note.");
      throw error; // Rethrow to handle in component
    }
  };

  return {
    notes,
    loading,
    handleCreateNote,
    handleUpdateNote,
    handleDeleteNote,
  };
};

export default useNotes;
