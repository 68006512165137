// src/hooks/useUsers.js
import { useState, useEffect } from "react";
import { message } from "antd";
import { getAllUsers, createUser, getUserById, updateUser, deleteUser } from "../api/users";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      console.log(`[fetchUsers] Fetching all users`);
      try {
        const response = await getAllUsers();
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("[fetchUsers] Error fetching users:", error);
        message.error("Failed to fetch users.");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCreateUser = async (userData) => {
    try {
      const response = await createUser(userData);
      const newUser = Array.isArray(response.data) ? response.data[0] : response.data;
      setUsers((prevUsers) => [...prevUsers, newUser]);
      message.success("User created successfully");
    } catch (error) {
      console.error("[handleCreateUser] Error creating user:", error);
      message.error("Failed to create user.");
    }
  };

  const handleUpdateUser = async (userId, userData) => {
    try {
      await updateUser(userId, userData);
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.user_id === userId ? { ...user, ...userData } : user))
      );
      message.success("User updated successfully");
    } catch (error) {
      console.error("[handleUpdateUser] Error updating user:", error);
      message.error("Failed to update user.");
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      setUsers((prevUsers) => prevUsers.filter((user) => user.user_id !== userId));
      message.success("User deleted successfully");
    } catch (error) {
      console.error("[handleDeleteUser] Error deleting user:", error);
      message.error("Failed to delete user.");
    }
  };

  const handleGetUserById = async (userId) => {
    try {
      const response = await getUserById(userId);
      return response.data;
    } catch (error) {
      console.error(`[handleGetUserById] Error fetching user with ID: ${userId}`, error);
      message.error("Failed to fetch user.");
      return null;
    }
  };

  return {
    users,
    loading,
    handleCreateUser,
    handleUpdateUser,
    handleDeleteUser,
    handleGetUserById,
  };
};

export default useUsers;
