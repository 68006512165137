// supabaseClient.js
// require('dotenv').config();

import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error(
    "Missing Supabase configuration in frontend environment variables."
  );
}

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  // Enable automatic session persistence
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
});

export default supabase;
